import React from 'react';
import Helmet from 'react-helmet';

import Section from '@latitude/section';

import Layout from '@/components/layout';
import AchPageHeaderSection from './_ach-page-header';
import GoMicrositeHeader from '../_go-microsite-header';

function AchDetailsPage(props) {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/gomastercard/additional-cardholder/details/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your Latitude GO Mastercard"
          />
          <title>Additional Card Holder Request | GO Mastercard</title>
        </Helmet>

        <AchPageHeaderSection />
        <GoMicrositeHeader />

        <Section>
          <div
            css={{ clear: 'both', margin: '10px 0' }}
            className="lfs-iframe-eform"
          >
            <iframe
              css={{
                width: '100%',
                minHeight: '800px',
                border: 'none',
                padding: '10px'
              }}
              title="Request an Additional Card Holder"
              src="https://eform.latitudefinancial.com.au/eform/Page.do?eFormName=ACH_gomc"
            />
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchDetailsPage;
